import React from "react"
import Back from "../images/palilula.jpg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { H1 } from "../components/theme"
import TextSection from "../components/sections/TextSection"
import HeaderSpace from "../components/sections/HeaderSpace"
import GallerySection from "../components/sections/GallerySection"
import LocationContactSection from "../components/sections/LocationContactSection"
import Image from "../images/PalilulaHeadImage.jpg"
import { photos, photosBig } from "../components/Photos"
import PriceSection from "../components/sections/PriceSection"

const Palilula = () => {
  return (
    <Layout>
      <SEO title="Fast Fit Zira" />
      <HeaderSpace back={Back} />
      <TextSection
        id="palilula"
        headline="FAST FIT ZIRA"
        par1="Fast Fit se nalazi u prelepom ambijentu modernog Hotela
        Zira. Svojim vežbačima na prvom mestu nudi inovativan i efikasan
        program treninga baziran na"
        span="EMS tehnologiji."
        par3="Vežbačima su na raspolaganju i drugi trening paketi bazirani na
        standardnim fitnes spravama."
        par2="Fast Fit za svoje vežbače obezbeđuje i dodatne pogodnosti
        poput korišćenja saune i parkinga u okviru Hotela Zira (1 sat)."
        img={Image}
      />
      <PriceSection />

      <GallerySection photosL={photos} photosBig={photosBig} />
      <LocationContactSection
        time="Ponedeljak - Petak: od 08h do 22h"
        time2="Subota: od 09h do 15h"
        time3="Nedeljom ne radimo"
        loc="Zira fitnes centar"
        addres="Ruzveltova 35, 11000 Beograd"
        href1="tel:+381644300100"
        tel1="Tel: 064 43 00 100"
        href2="tel:+381652202496"
        tel2="Tel: 065 22 02 496"
        mailto="mailto:info@fastfit.rs&subject=Za%20FastFit%20Palilula"
        mail="E-mail: info@fastfit.rs"
        map="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1415.3146216470795!2d20.482171400619723!3d44.80874402964102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7a99b9b93247%3A0x103d03e4da374e83!2sFast%20Fit!5e0!3m2!1sen!2srs!4v1593258202991!5m2!1sen!2srs"
      />
    </Layout>
  )
}

export default Palilula
